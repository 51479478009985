import React, { FC } from "react";
import styled from "styled-components";
import BasicTemplate from "@templates/BasicTemplate";
import { graphql } from "gatsby";
import { DirectusGallery, PropertyType } from "@interfaces/index";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import Contact from "@components/molecules/Contact/Contact";
import AboutUs from "@components/molecules/AboutUs/AboutUs";
import InvestmentPageContent from "@components/molecules/InvestmentPageContent/InvestmentPageContent";
import Gallery from "@components/molecules/Gallery/Gallery";
import { transformDirectusGallery } from "@utils/transformDirectusGallery";
import { decodeHtml } from "@utils/decodeHtml";

const StyledHero = styled.div`
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  @media (max-width: 1024px) {
    height: 45vh;
    margin-top: 65px;
  }
`;

const StyledName = styled.h1`
  text-align: center;
  padding: 0 5%;
`;

const StyledFeatureImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  z-index: -1;
  left: 0;
  top: 0;

  ::before {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background: #000;
    opacity: 0.7;
    z-index: 1;
  }
`;

const StyledLogo = styled.img`
  width: 220px;
  height: auto;

  @media (max-width: 1024px) {
    width: 180px;
  }

  @media (max-width: 720px) {
    width: 140px;
  }

  @media (max-width: 420px) {
    width: 100px;
  }
`;

const InvestmentTemplate: FC<Props> = ({
  data: {
    directus: { investment_by_id: investment, settings },
  },
}) => {
  const isGallery = !!investment.gallery.length;

  return (
    <BasicTemplate
      subpageTitle={investment.name}
      title={settings.seo_title}
      description={decodeHtml(
        investment.description.replace(/<[^>]*>?/gm, "").slice(0, 255)
      )}
      ogImage={investment.feature_image.imageFile.ogImage.fixed.src}
      renderHero={() => (
        <StyledHero data-scroll-section="">
          <StyledFeatureImage
            image={getImage(investment.feature_image.imageFile.childImageSharp)}
            alt={investment.name}
          />
          {investment.logo ? (
            <StyledLogo
              src={investment.logo.imageFile.publicURL}
              alt={investment.name}
            />
          ) : (
            <StyledName>{investment.name}</StyledName>
          )}
        </StyledHero>
      )}
    >
      <InvestmentPageContent
        description={investment.description}
        properties={investment.properties}
        slug={investment.slug}
        title={investment.title}
        name={investment.name}
        isGallery={isGallery}
      />

      {isGallery && (
        <Gallery
          images={transformDirectusGallery(investment.gallery)}
          name={investment.name}
        />
      )}
      <AboutUs />
      <Contact />
    </BasicTemplate>
  );
};

export const query = graphql`
  query ($id: ID!) {
    directus {
      settings {
        seo_title
      }
      investment_by_id(id: $id) {
        id
        name
        slug
        logo {
          id
          imageFile {
            id
            publicURL
          }
        }
        feature_image {
          id
          imageFile {
            id
            childImageSharp {
              id
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
            ogImage: childImageSharp {
              fixed(quality: 70, toFormat: JPG) {
                src
              }
            }
          }
        }
        title
        description
        properties
        gallery {
          directus_files_id {
            id
            imageFile {
              id
              fluid: childImageSharp {
                id
                gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
              }
              fixed: childImageSharp {
                id
                gatsbyImageData(quality: 100, formats: JPG, layout: FIXED)
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    directus: {
      settings: {
        seo_title: string;
      };
      investment_by_id: {
        name: string;
        slug: string;
        logo?: {
          id: string;
          imageFile: {
            publicURL: string;
          };
        };
        feature_image: {
          id: string;
          imageFile: {
            childImageSharp: ImageDataLike;
            ogImage: {
              fixed: {
                src: string;
              };
            };
          };
        };
        title: string;
        description: string;
        properties: PropertyType[];
        gallery?: DirectusGallery;
      };
    };
  };
}

export default InvestmentTemplate;
