import React, { FC } from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { PropertyType } from "@interfaces/index";
import HtmlParser from "react-html-parser";
import Property from "@components/atoms/Property/Property";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import Stars from "@components/atoms/Stars/Stars";

const StyledWrapper = styled.section<{ $isGallery: boolean }>`
  padding: 220px 0;
  background: ${({ theme }) => theme.backgroundSecondary};
  position: relative;

  ${({ $isGallery }) =>
    !$isGallery &&
    css`
      background: unset;
    `};

  @media (max-width: 1024px) {
    padding: 80px 0;
  }

  @media (max-width: 420px) {
    padding: 60px 0 80px;
  }
`;

const StyledInnerWrapper = styled.div`
  max-width: 1400px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
`;

const StyledLocationWrapper = styled.div`
  margin-bottom: 120px;

  @media (max-width: 1024px) {
    margin-bottom: 80px;
  }
`;

const StyledLocation = styled(Link)`
  color: ${({ theme }) => theme.foreground};
  font-size: 2rem;

  :first-of-type {
    margin-right: 5px;
  }

  :last-of-type {
    margin-left: 5px;
  }

  ${withLineHoverEffect()};

  @media (max-width: 720px) {
    font-size: 1.6rem;
  }
`;

const StyledPropertiesWrapper = styled.div`
  display: flex;
  margin: 0 auto 120px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 60px;
    width: calc(100% - 40px);
    margin-left: 40px;
  }

  @media (max-width: 720px) {
    margin-bottom: 40px;
  }
`;

const StyledProperty = styled(Property)`
  margin-right: 100px;

  :last-of-type {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin-bottom: 40px;
    &,
    :last-of-type {
      margin-right: 60px;
    }
  }
`;

const StyledContentInnerWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const StyledTitle = styled.h2`
  font-size: 4.8rem;
  max-width: 540px;
  margin: 0 120px 0 0;
  font-weight: 500;

  @media (max-width: 1340px) {
    margin: 0 40px 0 0;
  }

  @media (max-width: 1024px) {
    max-width: 700px;
    font-size: 3.4rem;
    line-height: 1.5;
    margin: 0 auto 60px;
  }

  @media (max-width: 720px) {
    margin: 0 auto 40px;
  }
`;

const StyledDescriptionWrapper = styled.div`
  flex: 1;

  p {
    line-height: 2;
    margin: 0 0 40px;

    :last-of-type {
      margin: 0;
    }
  }

  a {
    color: ${({ theme }) => theme.primary};
    text-decoration: underline;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 720px) {
    p {
      margin: 0 0 20px;
    }
  }
`;

const StyledStars = styled(Stars)`
  position: absolute;
  right: 70px;
  top: 120px;
`;

const InvestmentPageContent: FC<Props> = ({
  slug,
  title,
  description,
  properties,
  name,
  isGallery,
}) => (
  <StyledWrapper data-scroll-section="" $isGallery={isGallery}>
    <StyledInnerWrapper>
      <StyledLocationWrapper data-scroll="" data-scroll-speed="1">
        <StyledLocation to="/">Start</StyledLocation>/
        <StyledLocation to={`/${slug}`}>{name}</StyledLocation>
      </StyledLocationWrapper>

      <StyledPropertiesWrapper data-scroll="" data-scroll-speed="1">
        {properties.map((property, index) => (
          <StyledProperty propertyData={property} key={index} />
        ))}
      </StyledPropertiesWrapper>

      <StyledContentInnerWrapper>
        <StyledTitle data-scroll="" data-scroll-speed="2">
          {title}
        </StyledTitle>

        <StyledDescriptionWrapper data-scroll="" data-scroll-speed="1">
          {HtmlParser(description)}
        </StyledDescriptionWrapper>
      </StyledContentInnerWrapper>

      <StyledStars />
    </StyledInnerWrapper>
  </StyledWrapper>
);

interface Props {
  slug: string;
  title: string;
  description: string;
  properties: PropertyType[];
  name: string;
  isGallery: boolean;
}

export default InvestmentPageContent;
