import React, { FC, useState } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import arrowLeftIcon from "@iconify/icons-ep/arrow-left";
import arrowRightIcon from "@iconify/icons-ep/arrow-right";
import { Icon } from "@iconify/react";
import Lightbox from "@components/atoms/Lightbox/Lightbox";
import { transformLightboxImages } from "@utils/transformLightboxImages";
import { cssEaseOutExpo } from "@theme/easings";

SwiperCore.use([Navigation, Pagination]);

const StyledWrapper = styled.section`
  padding: 220px 0;
  position: relative;

  @media (max-width: 1024px) {
    padding: 120px 0;
  }

  @media (max-width: 720px) {
    padding: 80px 0;
  }
`;

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledHeadline = styled.h2`
  font-weight: 400;
  font-size: 4.8rem;
  margin: 0 0 20px;

  @media (max-width: 1024px) {
    font-size: 3.8rem;
  }
`;

const StyledParagraph = styled.p`
  margin: 0;
  text-align: center;

  span {
    font-weight: 500;
  }
`;

const StyledInnerWrapper = styled.div`
  position: relative;
  margin-top: 80px;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
  }
`;

const StyledSwiperWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  .swiper {
    max-width: 100%;
    overflow: hidden;
    position: relative;
  }

  .swiper-wrapper {
    display: inline-flex;
    align-items: center;
  }

  .swiper-slide {
    opacity: 0.2;
    transition: opacity 1s ${cssEaseOutExpo};
  }
  .swiper-slide-active {
    opacity: 1;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 68vw;
  height: 33.6vw;
  max-width: 1310px;
  max-height: 645px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 45vw;
  }
`;

const StyledNavButton = styled.button<{ $right?: boolean }>`
  position: absolute;
  background: transparent;
  border: none;
  font-size: 5rem;
  color: ${({ theme }) => theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  left: 165px;
  z-index: 99;
  cursor: pointer;
  padding: 40px;

  ${({ $right }) =>
    $right &&
    css`
      left: unset;
      right: 165px;
    `}

  :active {
    transform: translateY(-50%) scale(0.8);
  }

  @media (max-width: 1024px) {
    position: static;
    transform: none;
    padding: 10px;

    :active {
      transform: scale(0.8);
    }
  }

  @media (max-width: 420px) {
    font-size: 3rem;
    padding: 5px;
  }
`;

const StyledPagination = styled.div`
  display: flex;
  margin: 80px auto 0;
  justify-content: center;

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border: 1px solid ${({ theme }) => theme.washLight};
    margin-right: 20px;
    display: block;
    will-change: width;
    transition: width 1s ${cssEaseOutExpo}, background 1s ${cssEaseOutExpo},
      border 1s ${cssEaseOutExpo};
    border-radius: 50%;

    :last-of-type {
      margin-right: 0;
    }

    :hover {
      background: ${({ theme }) => theme.washLight};
      cursor: pointer;
    }
  }

  .swiper-pagination-bullet-active {
    width: 46px;
    height: 20px;
    background: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
    border-radius: 10px;

    :hover {
      cursor: default;
      background: ${({ theme }) => theme.primary};
    }
  }

  @media (max-width: 420px) {
    margin: 40px auto 0;
  }
`;

const Gallery: FC<Props> = ({ images, name }) => {
  const [swiper, setSwiper] = useState<SwiperCore>(null);
  const [isLightboxActive, setLightboxActive] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);

  const slideTo = (index: number) => swiper?.slideToLoop(index, 1000);

  const handleImageClick = (index: number) => {
    setCurrentImage(index);
    setLightboxActive(true);
  };

  const onSlideChange = (swiper: SwiperCore) => {
    setCurrentImage(swiper.realIndex);
  };

  return (
    <StyledWrapper data-scroll-section="">
      <StyledHeader data-scroll="" data-scroll-speed="1">
        <StyledHeadline>Galeria zdjęć</StyledHeadline>
        <StyledParagraph>
          Sprawdź jak możesz mieszkać na osiedlu <span>{name}</span>.
        </StyledParagraph>
      </StyledHeader>

      <StyledInnerWrapper>
        <StyledNavButton aria-label="Poprzednie zdjęcie" id="prev-button">
          <Icon icon={arrowLeftIcon} />
        </StyledNavButton>
        <StyledSwiperWrapper data-scroll="" data-scroll-speed="1">
          <Swiper
            grabCursor
            updateOnWindowResize
            centeredSlides
            loop
            loopedSlides={images.length}
            slidesPerView={1}
            breakpoints={{
              1025: {
                slidesPerView: "auto",
              },
            }}
            speed={1000}
            navigation={{
              prevEl: "#prev-button",
              nextEl: "#next-button",
            }}
            pagination={{ el: "#pagination-wrapper", clickable: true }}
            onSwiper={(swiper) => setSwiper(swiper)}
            onSlideChange={onSlideChange}
            spaceBetween={160}
          >
            {images.map(({ fluid, id }, index) => (
              <SwiperSlide key={id}>
                <StyledImage
                  image={getImage(fluid)}
                  alt=""
                  onClick={() => handleImageClick(index)}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledSwiperWrapper>

        <StyledNavButton aria-label="Następne zdjęcie" $right id="next-button">
          <Icon icon={arrowRightIcon} />
        </StyledNavButton>
      </StyledInnerWrapper>

      <StyledPagination
        id="pagination-wrapper"
        data-scroll=""
        data-scroll-speed="1"
      />

      <Lightbox
        currentImageIndex={currentImage}
        setCurrentImageIndex={setCurrentImage}
        isOpen={isLightboxActive}
        setOpen={setLightboxActive}
        images={transformLightboxImages(images)}
        slideTo={slideTo}
      />
    </StyledWrapper>
  );
};

interface Props {
  images: { id: string; fixed: ImageDataLike; fluid: ImageDataLike }[];
  name: string;
}

export default Gallery;
