import React, { FC, HTMLProps } from "react";
import styled from "styled-components";
import { PropertyType } from "@interfaces/index";
import { Icon } from "@iconify/react";
import { getIconFromPropertyType } from "@utils/getIconFromPropertyType";
import { getPropertyName } from "@utils/getPropertyName";
import { cssEaseOutExpo } from "@theme/easings";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledIconWrapper = styled.div`
  width: 90px;
  height: 90px;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.4rem;
  transition: background 1s ${cssEaseOutExpo}, color 1s ${cssEaseOutExpo};

  ${StyledWrapper}:hover & {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.background};
  }
`;

const StyledName = styled.h3`
  font-size: 1.6rem;
  margin: 15px 0 0;
  font-weight: 400;
  user-select: none;
`;

const StyledContent = styled.p`
  font-size: 1.6rem;
  margin: 6px 0 0;
  font-weight: 500;
  max-width: 160px;
  text-align: center;
  user-select: none;
`;

const Property: FC<Props> = ({ propertyData, ...props }) => (
  <StyledWrapper {...(props as unknown)}>
    <StyledIconWrapper>
      <Icon icon={getIconFromPropertyType(propertyData.type)} />
    </StyledIconWrapper>
    <StyledName>{getPropertyName(propertyData.type)}:</StyledName>
    <StyledContent>{propertyData.content}</StyledContent>
  </StyledWrapper>
);

interface Props extends HTMLProps<HTMLDivElement> {
  propertyData: PropertyType;
}

export default Property;
